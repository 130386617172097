import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, Text, Title } from '@mantine/core';

export function ContactUs () {
    return (
        <>
            <HeaderSimple />
            <Container size={"sm"} mt={100}>
                <Group align='center' justify='center' w={"100%"}>
                    <Image src={"/contact-us.png"} alt='shipping-delivery' w={"100%"} />
                </Group>
                <Group align='flex-start' justify='center' w={"100%"} opacity={0.9} style={{flexDirection: "column"}}>
                    <Text>مرحبًا بكم في متجرنا الإلكتروني! نحن هنا لخدمتكم ونرحب بجميع استفساراتكم وملاحظاتكم. يمكنكم التواصل معنا من خلال الطرق التالية:</Text>
                    <Text>
                        رقم الهاتف:
                        <Text span fw={'bold'}> 0665903994</Text>
                    </Text>
                    <Text>
                        البريد الإلكتروني:
                        <Text span fw={'bold'}> thahayacine@gmail.com </Text>
                    </Text>
                    <Text>
                        العنوان:
                        <Text span fw={'bold'}> 123 شارع التجارة، المدينة، الدولة</Text>
                    </Text>
                    <Text>
                        ساعات العمل:
                        <Text span fw={'bold'}> من الاثنين إلى الجمعة: 9:00 صباحًا - 6:00 مساءً / السبت: 10:00 صباحًا - 4:00 مساءً / الأحد: مغلق</Text>
                    </Text>

                    <Text>شكرًا لكم لاختياركم متجرنا. نتطلع لخدمتكم وتلبية جميع احتياجاتكم.</Text>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}